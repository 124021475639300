import React from 'react'
import SbEditable from '../../utils/sb-editable'
import ConditionalWrap from 'conditional-wrap'
import { Link } from 'components'

// Not all that useful for now, but gives us a consistent point to make improvements across the site.
const Image = ({ src = '', alt, link = false, offset = 0 }) => {
  let file
  let small = src
  let medium = src
  let large = src
  let placeholder = src
  if (src.includes('//a.storyblok.com')) {
    file = src.replace('//a.storyblok.com', '')
    small = `//img2.storyblok.com/300x0${file}`
    medium = `//img2.storyblok.com/960x0${file}`
    large = `//img2.storyblok.com/1920x0${file}`
    placeholder = `//img2.storyblok.com/100x0${file}`
  }
  return (
    <ConditionalWrap condition={link} wrap={children => <Link to={link}>{children}</Link>}>
      <img src={small} srcSet={`${small} 300w, ${medium} 768w, ${large} 1280w`} alt={alt} />
    </ConditionalWrap>
  )
}

export const EditableImage = ({ blok }) => (
  <SbEditable content={blok}>
    <Image src={blok.image} alt={blok.text} link={blok.link && blok.link.cached_url} />
  </SbEditable>
)

export default Image
